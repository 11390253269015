exports.components = {
  "component---src-pages-bw-monitor-1-js": () => import("./../../../src/pages/bw_monitor1.js" /* webpackChunkName: "component---src-pages-bw-monitor-1-js" */),
  "component---src-pages-bw-monitor-2-js": () => import("./../../../src/pages/bw_monitor2.js" /* webpackChunkName: "component---src-pages-bw-monitor-2-js" */),
  "component---src-pages-bw-tapping-js": () => import("./../../../src/pages/bw_tapping.js" /* webpackChunkName: "component---src-pages-bw-tapping-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-rp-monitor-1-js": () => import("./../../../src/pages/rp_monitor1.js" /* webpackChunkName: "component---src-pages-rp-monitor-1-js" */),
  "component---src-pages-rp-monitor-2-js": () => import("./../../../src/pages/rp_monitor2.js" /* webpackChunkName: "component---src-pages-rp-monitor-2-js" */),
  "component---src-pages-rp-party-js": () => import("./../../../src/pages/rp_party.js" /* webpackChunkName: "component---src-pages-rp-party-js" */),
  "component---src-pages-rp-signage-beer-js": () => import("./../../../src/pages/rp_signage_beer.js" /* webpackChunkName: "component---src-pages-rp-signage-beer-js" */),
  "component---src-pages-rp-signage-food-js": () => import("./../../../src/pages/rp_signage_food.js" /* webpackChunkName: "component---src-pages-rp-signage-food-js" */),
  "component---src-pages-rp-signage-js": () => import("./../../../src/pages/rp_signage.js" /* webpackChunkName: "component---src-pages-rp-signage-js" */),
  "component---src-pages-rp-tapping-js": () => import("./../../../src/pages/rp_tapping.js" /* webpackChunkName: "component---src-pages-rp-tapping-js" */)
}

